<template>
  <div :style="disabled ? 'cursor: not-allowed;' : ''">
    <div v-if="imagePreview" @click="closePreview" class="preview-screen">
      <img @click.stop class="preview-image image-fade" :src="imagePreview" alt="img">
    </div>
    <a-divider style="margin-top: 34px;" class="mb-4">{{ type === 'image' ? 'Creatives' : 'Linear Creatives' }}</a-divider>
    <div v-if="videoPreview" @click="closePreview" class="preview-screen video-fade">
      <video class="video-preview" @click.stop autoplay controls>
        <source :src="videoPreview" type="video/mp4">
      </video>
    </div>
    <div class="upload-container">
      <div class="w-70">
        <CreativesDraggable :disabled="disabled" :type="type === 'image' ? 'image' : 'video'" :fileList="fileList" :receivedCreatives="receivedCreatives" />
      </div>
      <div class="or my-2 my-md-0">OR</div>
      <!-- select dropdown -->
      <div :style="disabled ? 'background-color: #fafafa; opacity: 60%;' : ''" class="dropdown-add w-30 my-md-0">
        <a-select :disabled="disabled" v-if="type === 'image' && creatives" class="input-width" style="width: 250px" v-model="temporaryCreative" placeholder="Select Creative">
          <a-select-option label="Add Creative" :defaultValue="1" v-for="obj in creatives.imageCreatives" :key="obj.Id">
            [{{obj.Id}}] {{ obj.Name }}
          </a-select-option>
        </a-select>
        <a-select :disabled="disabled" v-if="type === 'video' && creatives" class="input-width" style="width: 250px" v-model="temporaryCreative" placeholder="Select Creative">
          <a-select-option label="Add Creative" :defaultValue="1" v-for="obj in creatives.videoCreatives" :key="obj.Id">
            [{{obj.Id}}] {{ obj.Name }}
          </a-select-option>
        </a-select>
        <a-button :disabled="disabled" class="ml-1 px-2 py-0" type="primary" @click="addCreative">Add +</a-button>
      </div>
    </div>
      <!-- image creatives -->
    <b-table
      v-if="type === 'image'"
      :bordered="true"
      :fields="imageFields"
      :hover="true"
      :items="receivedCreatives"
      :small="true"
      :striped="true"
      show-empty
      stacked="md"
      class="mt-3"
    >
      <template #cell(Name)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Creative's name.</span>
            </template>
            <a-input-search @keypress="$event.keyCode === 13 ? $event.preventDefault() : ''" placeholder="Creative Name" v-model="data.item.Name" size="small" @search="updateCreative(data.item.Id, data.item.Name)">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Creative's name.</span>
            </template>
            <a-input-search @keypress="$event.keyCode === 13 ? $event.preventDefault() : ''" placeholder="Creative Name" v-model="data.item.Name" size="small" @search="updateCreative(data.item.Id, data.item.Name)">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>
      <template #cell(CampaignName)="data">
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div>
            <!-- <input class="inline-input" type="text" v-model="data.item.Name" @change="updateCreative(data.item.Id, data.item.Name)"> -->
            <!-- <a-input size="small" placeholder="Creative Name" type="text" v-model="data.item.Name" @change="updateCreative(data.item.Id, data.item.Name)" /> -->
            <a-input-search @keypress="$event.keyCode === 13 ? $event.preventDefault() : ''" placeholder="Creative Name" v-model="data.item.Name" size="small" @search="updateCreative(data.item.Id, data.item.Name)">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </div>
        </div>
        <div class="d-none d-sm-block">
          <div>
            <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName" class="" v-if="data.item.CampaignName.length<20">{{ data.item.CampaignName }}</div>
            <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName" class="" v-if="data.item.CampaignName.length>=20">{{ data.item.CampaignName.substring(0,20)+".." }}</div>
          </div>
        </div>
      </template>
      <template #cell(Dimensions)="data">
        <div class="">{{ data.item.Width }}x{{ data.item.Height }}</div>
      </template>
      <template #cell(Ratio)="data">
        {{ data.item.WidthRatio }}:{{ data.item.HeightRatio }}
      </template>
      <template #cell(FileSize)="data">
        {{ bytesToSize(data.item.FileSize) }}
      </template>
      <template #cell(Type)="data">
        {{ creativeType(data.item.Type) }}
      </template>
      <template #cell(MediaRating)="data">
        <!-- {{ convertMediaRating(data.item.MediaRating) }} -->
          <a-tooltip placement="top">
            <template slot="title">
              <span>Creative media rating per IQG guidelines.</span>
            </template>
            <a-select :defaultValue="convertMediaRating(data.item.MediaRating)" size="small" class="select-menu" placeholder="Media Rating" @change="updateCreative(data.item.Id, data.item.Name, $event)">
              <a-select-option value="1">
                All Audiences
              </a-select-option>
              <a-select-option value="2">
                Everyone Over 12
              </a-select-option>
              <a-select-option value="3">
                Mature Audiences
              </a-select-option>
            </a-select>
          </a-tooltip>
      </template>
      <template #cell(Preview)="data">
        <img class="picture-block pointer" @click="imagePreview = link + data.item.DownloadUrl" :src="link + data.item.DownloadUrl">
      </template>
      <template #cell(actions)="data">
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click="onDelete(data.item.Id)"
        ><i class="fa fa-trash"></i></b-button>
      </template>
    </b-table>
    <!-- video creatives -->
    <b-table
      v-if="type === 'video'"
      :style="disabled ? 'opacity: 40%;' : ''"
      :bordered="true"
      :fields="videoFields"
      :hover="true"
      :items="receivedCreatives"
      :small="true"
      :striped="true"
      show-empty
      stacked="md"
      class="mt-3 table-responsive-lg"
    >
      <template #cell(Name)="data">
        <div class="d-block d-sm-none">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Creative's name.</span>
            </template>
            <a-input-search @keypress="$event.keyCode === 13 ? $event.preventDefault() : ''" placeholder="Creative Name" v-model="data.item.Name" size="small" @search="updateCreative(data.item.Id, data.item.Name)">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
        <div class="d-none d-sm-block">
          <a-tooltip placement="top">
            <template slot="title">
              <span>Creative's name.</span>
            </template>
            <a-input-search @keypress="$event.keyCode === 13 ? $event.preventDefault() : ''" placeholder="Creative Name" v-model="data.item.Name" size="small" @search="updateCreative(data.item.Id, data.item.Name)">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </a-tooltip>
        </div>
      </template>
      <template #cell(CampaignName)="data">
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div>
            <a-input-search @keypress="$event.keyCode === 13 ? $event.preventDefault() : ''" placeholder="Creative Name" v-model="data.item.Name" size="small" @search="updateCreative(data.item.Id, data.item.Name)">
              <a-button slot="enterButton">
                <span style="fontSize: 90%;" class="material-icons-outlined">
                  done
                </span>
              </a-button>
            </a-input-search>
          </div>
        </div>
        <div class="d-none d-sm-block">
          <div>
            <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName" class="" v-if="data.item.CampaignName.length<20">{{ data.item.CampaignName }}</div>
            <div data-toggle="tooltip" data-placement="top" :title="data.item.CampaignName" class="" v-if="data.item.CampaignName.length>=20">{{ data.item.CampaignName.substring(0,20)+".." }}</div>
          </div>
        </div>
      </template>
      <template #cell(Dimensions)="data">
        <div class="">{{ data.item.Width }}x{{ data.item.Height }}</div>
      </template>
      <template #cell(Ratio)="data">
        {{ data.item.WidthRatio }}:{{ data.item.HeightRatio }}
      </template>
      <template #cell(FileSize)="data">
        {{ bytesToSize(data.item.FileSize) }}
      </template>
      <template #cell(Type)="data">
        {{ creativeType(data.item.Mime) }}
      </template>
      <template #cell(Duration)="data">
        {{ convertDuration(data.item.Duration) }}
      </template>
      <template #cell(Framerate)="data">
        {{ data.item.Framerate }} FPS
      </template>
      <template #cell(Bitrate)="data">
        {{ data.item.Bitrate }}&nbsp;kbps
      </template>
      <template #cell(MediaRating)="data">
        <!-- {{ convertMediaRating(data.item.MediaRating) }} -->
          <a-tooltip placement="top">
            <template slot="title">
              <span>Creative media rating per IQG guidelines.</span>
            </template>
            <a-select :defaultValue="convertMediaRating(data.item.MediaRating)" size="small" class="select-menu" placeholder="Media Rating" @change="updateCreative(data.item.Id, data.item.Name, $event)">
              <a-select-option value="1">
                All Audiences
              </a-select-option>
              <a-select-option value="2">
                Everyone Over 12
              </a-select-option>
              <a-select-option value="3">
                Mature Audiences
              </a-select-option>
            </a-select>
          </a-tooltip>
      </template>
      <template #cell(Preview)="data">
        <a-icon  @click="videoPreview = link + data.item.DownloadUrl" class="cpointer"  style="fontSize: 140%" type="play-circle" />
      </template>
      <template #cell(actions)="data">
        <b-button
          class="btn-info mr-1"
          size="sm"
          @click="onDelete(data.item.Id)"
        ><i class="fa fa-trash"></i></b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import Vue from 'vue'
import { updateCreative } from '@/api/display/creative'
import { Modal } from 'ant-design-vue'
export default {
  props: ['fileList', 'receivedCreatives', 'type', 'unfilteredCreatives', 'disabled'],
  components: {
    CreativesDraggable: () => import('@/components/custom/creatives/creativesDraggable/index.vue'),
  },
  data() {
    return {
      fileListValue: this.fileList,
      imagePreviewValue: this.imagePreview,
      imagePreview: false,
      videoPreview: false,
      temporaryCreative: 'Choose Existing Creative',
      link: process.env.NODE_ENV === 'production' ? window.location.origin : 'https://dev.rtb.me',
      imageFields: [
        {
          key: 'Preview',
          label: 'Preview',
          show: true,
          class: 'text-center',
          thStyle: 'width: 180px; max-height:200px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          class: 'text-center',
          tdClass: 'text-md-right text-center align-middle',
          thStyle: 'width:50px',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          headerTitle: 'Name',
          sortable: false,
          tdClass: 'align-middle',
          // thStyle: 'max-height:200px',
          thStyle: 'width: 250px',
        },
        {
          key: 'Dimensions',
          label: 'Dimensions',
          show: true,
          headerTitle: 'Dimensions',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Ratio',
          label: 'Ratio',
          show: true,
          headerTitle: 'Ratio',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'FileSize',
          label: 'Size',
          show: true,
          headerTitle: 'Size',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Type',
          label: 'Type',
          show: true,
          headerTitle: 'Type',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'max-height:200px',
        },
        {
          key: 'MediaRating',
          label: 'Media Rating',
          show: true,
          headerTitle: 'Media Rating',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width:200px; max-height:200px;',
        },
        {
          key: 'actions',
          label: '',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px',
        },
      ],
      videoFields: [
        {
          key: 'Preview',
          label: 'Preview',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'max-height:200px; width:50px',
        },
        {
          key: 'Id',
          label: 'ID',
          show: true,
          class: 'text-center',
          tdClass: 'text-md-right text-center',
          thStyle: 'width:50px',
        },
        {
          key: 'Name',
          label: 'Name',
          show: true,
          headerTitle: 'Name',
          sortable: false,
          tdClass: 'align-middle',
          // thStyle: 'max-height:200px',
          thStyle: 'width: 250px',
        },
        {
          key: 'Dimensions',
          label: 'Dimensions',
          show: true,
          headerTitle: 'Dimensions',
          sortable: false,
          tdClass: 'align-middle text-md-left text-center',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Ratio',
          label: 'Ratio',
          show: true,
          headerTitle: 'Ratio',
          sortable: false,
          tdClass: 'align-middle text-md-left text-center',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Duration',
          label: 'Duration',
          show: true,
          headerTitle: 'Duration',
          sortable: false,
          tdClass: 'align-middle text-md-left text-center',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Framerate',
          label: 'Framerate',
          show: true,
          headerTitle: 'Framerate',
          sortable: false,
          tdClass: 'align-middle text-md-left text-center',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Codec',
          label: 'Codec',
          show: true,
          headerTitle: 'Codec',
          sortable: false,
          tdClass: 'align-middle text-md-left text-center',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Bitrate',
          label: 'Bitrate',
          show: true,
          headerTitle: 'Bitrate',
          sortable: false,
          tdClass: 'align-middle text-md-left text-center',
          thStyle: 'max-height:200px',
        },
        {
          key: 'FileSize',
          label: 'Size',
          show: true,
          headerTitle: 'Size',
          sortable: false,
          tdClass: 'align-middle text-md-left text-center',
          thStyle: 'max-height:200px',
        },
        {
          key: 'Type',
          label: 'Type',
          show: true,
          headerTitle: 'Type',
          sortable: false,
          tdClass: 'align-middle text-md-left text-center',
          thStyle: 'max-height:200px',
        },
        {
          key: 'MediaRating',
          label: 'Media Rating',
          show: true,
          headerTitle: 'Media Rating',
          sortable: false,
          tdClass: 'align-middle',
          thStyle: 'width:200px; max-height:200px;',
        },
        {
          key: 'actions',
          label: '',
          show: true,
          class: 'text-center',
          tdClass: 'align-middle',
          thStyle: 'width:50px; max-height:200px;',
        },
      ],
    }
  },
  computed: {
    creatives () {
      const imageCreatives = []
      const videoCreatives = []
      this.unfilteredCreatives.map((el) => {
        if (!this.receivedCreatives.includes(el)) {
          if (el.Type === 0) {
            imageCreatives.push(el)
          } else if (el.Type === 1) {
            videoCreatives.push(el)
          }
        }
      })
      return { imageCreatives, videoCreatives }
    },
  },
  methods: {
    addCreative() {
      if (this.temporaryCreative !== 'Choose Existing Creative') {
        const tempCreative = this.creatives[this.type === 'image' ? 'imageCreatives' : 'videoCreatives'].find((el) => el.Id === this.temporaryCreative)
        if (tempCreative) {
          if (!this.receivedCreatives.find((el) => el.Id === this.temporaryCreative)) {
            this.receivedCreatives.push(tempCreative)
            this.temporaryCreative = 'Choose Existing Creative'
            Vue.prototype.$notification.success({
              message: 'Creative added.',
            })
          } else {
            Vue.prototype.$notification.warning({
              message: 'Creative already added.',
            })
          }
        } else {
          Vue.prototype.$notification.error({
            message: 'Invalid Creative.',
          })
        }
      }
    },
    bytesToSize(bytes) {
      const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return 'n/a'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      if (i === 0) return bytes + ' ' + sizes[i]
      return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i]
    },
    convertDuration(duration) {
      const minutes = duration < 60 ? '00' : parseInt(duration / 60)
      let seconds = duration % 60 ? duration % 60 : '0'
      if (seconds < 10) { seconds = `0${seconds}` }
      return `${minutes}:${seconds}`
    },
    creativeType(type) {
      if (type === 0) {
        return 'PNG'
      } else if (type === 1) {
        return 'JPEG'
      } else if (type === 2) {
        return 'MPEG4'
      } else if (type === 3) {
        return 'WEBM'
      }
    },
    emitImagePreview(val) {
      this.imagePreviewValue = val
      this.$emit('image-preview', { imagePreview: this.imagePreviewValue })
    },
    closePreview() {
      this.imagePreview = false
      this.videoPreview = false
    },
    convertMediaRating(val) {
      if (val === 1) {
        return 'All Audiences'
      } else if (val === 2) {
        return 'Everyone Over 12'
      } else if (val === 3) {
        return 'Mature Audiences'
      } else {
        return undefined
      }
    },
    onDelete(creativeId) {
      Modal.confirm({
        title: 'Are you sure you want to delete this creative?',
        onOk: () => {
          const index = this.receivedCreatives.findIndex(el => el.Id === creativeId)
          this.receivedCreatives.splice(index, 1)
        },
      })
    },
    updateCreative(Id, Name, MediaRating) {
      MediaRating = parseInt(MediaRating)
      const payload = { Id, Name, MediaRating }
      Vue.prototype.$notification.success({
        message: 'Creative Updated.',
        description: `Creative: ${Name} Updated.`,
      })
      updateCreative(payload)
    },
  },
  watch: {
    fileList(val) {
      this.fileListValue = val
    },
    imagePreview(val) {
      this.imagePreviewValue = val
    },
  },
}
</script>

<style lang="css" scoped>
.preview-screen {
  background-color:rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100001;
  min-height: 100%;
  min-width: 100%;
}
.image-fade {
  -webkit-animation: fadein 0.2s;
  -moz-animation: fadein 0.2s;
  -ms-animation: fadein 0.2s;
  -o-animation: fadein 0.2s;
  animation: fadein 0.2s;
}
.video-fade {
  -webkit-animation: fadein 0.45s;
  -moz-animation: fadein 0.45s;
  -ms-animation: fadein 0.45s;
  -o-animation: fadein 0.45s;
  animation: fadein 0.45s;
}
.cpointer {
  cursor: pointer;
}
.inline-input {
  outline: none;
  border: none;
  background: none;
}
.preview-image {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  max-height: 600px;
}
.dropdown-add {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 1px solid #e4e9f0;
  outline-style: dashed;
  border-radius: 5px;
}
.pointer {
  cursor: pointer;
}
/* .creative {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding-left: 0em;
  padding-right: 0em;
} */
.creative {
  display: flex;
  justify-content: space-evenly;
  padding-left: 3em;
  padding-right: 3em;
}
.creatives-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 100% !important;
  outline: 1px solid #e8e8e8;
  list-style: none;
  border-radius: 3px;
  /* padding-bottom: 5px; */
  padding-left: 50px;
  padding-right: 50px;
  font-size: 95%;
}
.main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 230px;
  border-bottom: solid 1px #e8e8e8;
}
.ant-form-item {
  margin-bottom: 5px !important;
}
.text-container {
  display: flex;
  flex-basis: 350px;
  font-size: 100%;
  justify-content: space-between;
  align-items: center;
}
.delete-button {
  border:none;
  background: none;
  font-size: 140%;
  -webkit-transition: color .15s;
}
.delete-button:hover {
  color: #f5222e;
  transition: all .15s ease-in;
  transition: all .15s ease-out;
}
.preview {
  /* display: flex; */
  /* justify-content: center; */
  flex-basis: 200px !important;
  text-align: center;
  flex-grow: 1;
}

  @media only screen and (min-width: 768px) {
    .image-block {
      width: 170px !important;
      height: 170px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .picture-block {
      max-width: 170px !important;
      max-height: 170px !important;
    }
    .upload-container {
      display: flex;
      justify-content: space-between;
      text-align: center;
    }
    .or {
      font-size: 125%;
      align-self: center;
      color: #7b7b7b;
    }
  /* .input-width {
    width: 150px;
  } */
    .w-70 {
      width:47.5% !important;
    }
    .w-30 {
      width: 47.5% !important;
    }
    .flex {
      display: flex;
      flex-direction: col;
      width: 100%;
    }
    .creatives-list {
      padding-left: none;
      padding-right: none;
    }
    li:last-child{
      border-bottom: none !important;
    }
    li:first-child{
      border-bottom: solid 1px #e8e8e8 !important;
    }
  .align-cell-center {
    display: flex;
    align-items: center;
  }
  }
  /* SM SCREEN */
  @media only screen and (max-width: 768px) {
    .creative {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 3em;
      padding-right: 3em;
    }
    .video-preview {
      width: 250px !important;
      height: auto !important;
    }
    .input-width {
      width: 80% !important;
    }
    .dropdown-add {
      padding-top: 2em;
      padding-bottom: 2em;
    }
    .preview-image {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 300px;
      max-height: 600px;
    }
    .creatives-list {
      display: flex;
      flex-direction: column;
      width: 100% !important;
      min-height: 100% !important;
      outline: 1px solid #e8e8e8;
      list-style: none;
      border-radius: 3px;
      padding-top: 10px;
      margin-top: 10px;
      font-size: 95%;
      margin-right: 0px;
      margin-left: 0px;
      padding-left: 8px;
      padding-right: 4px;
    }
    .creative {
      padding-left: 0em;
      padding-right: 0em;
    }
    .image-block {
      width: 50px !important;
      height: 50px !important;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .picture-block {
      max-width: 50px !important;
      max-height: 50px !important;
    }
    .main-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      padding-bottom: 50px;
      padding-top: 50px;
      padding-left: 1em;
      padding-right: 1em;
      border-bottom: solid 1px #e8e8e8;
    }
    .text-container {
      display: flex;
      flex-basis: 120px;
      font-size: 70%;
      justify-content: space-between;
    }
    .delete-button {
      border:none;
      background: none;
      font-size: 120%;
      -webkit-transition: color .15s;
    }
    .or {
      font-size: 125%;
      text-align: center;
      align-self: center;
      justify-self: center;
      color: #7b7b7b;
    }
  }
  @keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}
@media screen
  and (min-width: 321px)
  and (max-width: 768px){
  .dropdown-add {
    padding-left: 1em;
    padding-right: 1em;
  }
  .text-container {
    display: flex;
    flex-basis: 300px;
    font-size: 85%;
    justify-content: space-between;
  }
  .select-menu {
  }
}
@media screen
  and (max-width: 320px) {
    .preview-image {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      max-width: 300px;
      max-height: 600px;
    }
    .dropdown-add {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
@media (min-width: 769px) and (max-width: 875px) {
  .input-width {
    width: 200px !important;
  }
}
</style>
